export type HdmiCommand =
    | 'POWER_ON'
    | 'POWER_OFF'
    | 'VOLUME_UP'
    | 'VOLUME_DOWN'
    | 'MUTE'
    | 'INPUT_HDMI1'
    | 'INPUT_HDMI2'
    | 'INPUT_HDMI3';

const LOCAL_BACKEND_URL = process.env.REACT_APP_LOCAL_BACKEND_URL || 'http://localhost:3777';

const getEndpointForCommand = (command: HdmiCommand): string => {
    switch (command) {
        case 'POWER_ON':
            return '/tv/power/on';
        case 'POWER_OFF':
            return '/tv/power/standby';
        case 'VOLUME_UP':
            return '/tv/volume/up';
        case 'VOLUME_DOWN':
            return '/tv/volume/down';
        case 'MUTE':
            return '/tv/volume/mute';
        // Note: HDMI input switching not implemented in box service yet
        case 'INPUT_HDMI1':
        case 'INPUT_HDMI2':
        case 'INPUT_HDMI3':
            throw new Error('HDMI input switching not supported');
        default:
            throw new Error(`Unknown command: ${command}`);
    }
};

export const executeHdmiCommand = async (command: HdmiCommand): Promise<void> => {
    try {
        const endpoint = getEndpointForCommand(command);
        const response = await fetch(`${LOCAL_BACKEND_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        const data = await response.json();

        if (!response.ok || !data.success) {
            throw new Error(`Failed to execute HDMI command: ${command}`);
        }

    } catch (error) {
        console.error('Error executing HDMI command:', error);
        throw error;
    }
}; 