import React, { useState, useEffect, useCallback } from 'react';
import ReactPlayer from 'react-player';

interface SplitScreenProps {
    ads: Array<{ mediaLink: string }>;
    splitCount: number;
}

const SplitScreen: React.FC<SplitScreenProps> = ({ ads, splitCount }) => {
    const is2To1Layout = splitCount === 2;
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);

    const isVideo = (url: string) => {
        const videoFileRegex = /\.(mp4|webm|ogg|mov|avi|mkv)$/i;
        return videoFileRegex.test(url);
    };

    // Calculate total number of slides needed
    const itemsPerSlide = is2To1Layout ? 2 : splitCount;
    const totalSlides = is2To1Layout 
        ? Math.floor(ads.length / 2) // Only count complete pairs for 2:1
        : Math.ceil(ads.length / splitCount);

    // Reset current slide if it's beyond the new total
    useEffect(() => {
        if (currentSlideIndex >= totalSlides) {
            setCurrentSlideIndex(0);
        }
    }, [totalSlides, currentSlideIndex]);

    // Get current slide's items and check if it's valid
    const getCurrentSlideItems = () => {
        // Safety check for empty ads array
        if (ads.length === 0) return [];
        
        const startIndex = currentSlideIndex * itemsPerSlide;
        const items = ads.slice(startIndex, startIndex + itemsPerSlide);
        
        // For 2:1 layout, ensure we have at least 2 items
        if (is2To1Layout) {
            if (items.length < 2) {
                // If we're not on the first slide, go back to start
                if (currentSlideIndex > 0) {
                    setCurrentSlideIndex(0);
                    return ads.slice(0, 2);
                }
                return []; // Return empty array for invalid 2:1 slides
            }
        }
        return items;
    };

    // Handle slide transition - declare before useEffect
    const advanceSlide = useCallback(() => {
        // Only transition if we have more than one valid slide
        if (!isTransitioning && totalSlides > 1) {
            setIsTransitioning(true);
            setTimeout(() => {
                setCurrentSlideIndex(prev => (prev + 1) % totalSlides);
                setIsTransitioning(false);
            }, 500);
        }
    }, [isTransitioning, totalSlides]);

    // Auto-advance slides only if there are multiple slides
    useEffect(() => {
        // Only set timer if we have more than one valid slide
        if (totalSlides > 1) {
            const timer = setTimeout(advanceSlide, 7500);
            return () => clearTimeout(timer);
        }
    }, [currentSlideIndex, advanceSlide, totalSlides]);

    const render2To1Layout = () => {
        const currentItems = getCurrentSlideItems();
        if (currentItems.length < 2) return null;

        return (
            <div style={{
                position: 'relative',
                width: '100%',
                height: '100vh',
                backgroundColor: '#000',
                overflow: 'hidden',
                opacity: totalSlides > 1 ? (isTransitioning ? 0 : 1) : 1,
                transition: totalSlides > 1 ? 'opacity 0.5s ease-in-out' : 'none'
            }}>
                {/* Main Section */}
                <div style={{
                    position: 'absolute',
                    left: '-10%',
                    top: '-2%',
                    width: '75%',
                    height: '104%',
                    backgroundColor: '#000',
                    overflow: 'hidden',
                    transform: 'skewX(-15deg)',
                    borderRight: '15px solid white',
                    zIndex: 1
                }}>
                    <div style={{
                        position: 'absolute',
                        left: '10%',
                        top: '2%',
                        width: '100%',
                        height: '100%',
                        transform: 'skewX(15deg) scale(1.1)'
                    }}>
                        {isVideo(currentItems[0].mediaLink) ? (
                            <ReactPlayer
                                url={currentItems[0].mediaLink}
                                playing={true}
                                controls={false}
                                muted={true}
                                loop={true}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                            />
                        ) : (
                            <img
                                src={currentItems[0].mediaLink}
                                alt="Main content"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                    </div>
                </div>

                {/* Logo */}
                <div style={{
                    position: 'absolute',
                    left: '66%',
                    top: '50%',
                    width: 98,
                    height: 98,
                    transform: 'translate(-49px, -49px) skewX(-15deg)',
                    zIndex: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <img
                        src="/Android.png"
                        alt="Logo"
                        style={{
                            width: '100%',
                            height: '100%',
                            transform: 'skewX(15deg)'
                        }}
                    />
                </div>

                {/* Side Section */}
                <div style={{
                    position: 'absolute',
                    right: '-10%',
                    top: '-2%',
                    width: '42%',
                    height: '104%',
                    backgroundColor: '#000',
                    overflow: 'hidden',
                    transform: 'skewX(-15deg)',
                    borderLeft: '15px solid white'
                }}>
                    <div style={{
                        position: 'absolute',
                        right: '10%',
                        top: '2%',
                        width: '100%',
                        height: '100%',
                        transform: 'skewX(15deg) scale(1.1)'
                    }}>
                        {isVideo(currentItems[1].mediaLink) ? (
                            <ReactPlayer
                                url={currentItems[1].mediaLink}
                                playing={true}
                                controls={false}
                                muted={true}
                                loop={true}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                            />
                        ) : (
                            <img
                                src={currentItems[1].mediaLink}
                                alt="Side content"
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain'
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const renderGridLayout = () => {
        const currentItems = getCurrentSlideItems();
        // Safety check - don't render if no items
        if (currentItems.length === 0) return null;

        const gridColumns = Math.ceil(Math.sqrt(splitCount));

        return (
            <div style={{ 
                display: 'grid', 
                gridTemplateColumns: `repeat(${gridColumns}, 1fr)`, 
                height: '100vh',
                opacity: totalSlides > 1 ? (isTransitioning ? 0 : 1) : 1,
                transition: totalSlides > 1 ? 'opacity 0.5s ease-in-out' : 'none'
            }}>
                {currentItems.map((ad, index) => (
                    isVideo(ad.mediaLink) ? (
                        <div key={`video-slide-${index}-${ad.mediaLink}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <ReactPlayer
                                url={ad.mediaLink}
                                playing={true}
                                controls={false}
                                muted={true}
                                loop={true}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                            />
                        </div>
                    ) : (
                        <div key={`image-${index}-${ad.mediaLink}`} style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                alt={`Media ${index}`}
                                src={ad.mediaLink}
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </div>
                    )
                ))}
            </div>
        );
    };

    // Final safety check before rendering
    if (ads.length === 0) return null;

    return is2To1Layout ? render2To1Layout() : renderGridLayout();
};

export default SplitScreen;
