import axios from 'axios';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface PinDialogProps {
    open: boolean;
    onClose: () => void;
}

const API_URL = process.env.REACT_APP_API_URL;

const PinDialog: React.FC<PinDialogProps> = ({ open, onClose }) => {
    const [pinDigits, setPinDigits] = useState(['', '', '', '']);
    const [locked, setLocked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [step, setStep] = useState<'pin' | 'tvNumber' | 'rememberPin'>('pin');
    const [tvNumber, setTvNumber] = useState(['', '']);
    const [companyId, setCompanyId] = useState("");
    const [tvIds, setTvIds] = useState<number[]>([]);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
    const navigate = useNavigate();

    const skipToPlay = useCallback((companyId: string, storedTvNumber: string, storedTvIds: number[]) => {
        setLocked(true);
        setCompanyId(companyId);
        setTvIds(storedTvIds);

        requestFullScreen();

        navigate('/play', { state: { companyId: companyId, tvId: parseInt(storedTvNumber) } });
        onClose();
    }, [navigate, onClose]);


    useEffect(() => {
        // Check if PIN, TV number, and companyId are stored in localStorage
        const storedPin = localStorage.getItem('pin');
        const storedTvNumber = localStorage.getItem('tvNumber');
        const storedCompanyId = localStorage.getItem('companyId');
        const storedTvIds = localStorage.getItem('tvIds') ? JSON.parse(localStorage.getItem('tvIds') || '[]') : [];

        if (storedPin && storedTvNumber && storedCompanyId && storedTvIds.length) {
            skipToPlay(storedCompanyId, storedTvNumber, storedTvIds);
        }
    }, [skipToPlay]);

    useEffect(() => {
        if (open && inputRefs.current[0]) {
            inputRefs.current[0].focus();
        }
    }, [open, step]);

    async function verifyPin(pin: string) {
        try {
            const response = await axios.post(API_URL + '/company/validatePin', {
                pin: pin
            });
            setCompanyId(response.data.companyId);
            setTvIds(response.data.tvIds); // Store TV IDs
            return response.data.companyId;
        } catch (error: any) {
            if (error.response) {
                // Handle 401 and 500 status codes
                if (error.response.status === 401 || error.response.status === 500) {
                    return undefined;
                }
            }
            console.error("Error during PIN validation: ", error);
            return undefined; // Return false for any other kind of error
        }
    }

    const handleInputChange = async (index: number, value: string) => {
        if (locked) return;

        const newPinDigits = [...pinDigits];
        newPinDigits[index] = value;
        setPinDigits(newPinDigits);

        if (value && index < 3) {
            const nextInputRef = inputRefs.current[index + 1];
            if (nextInputRef) {
                nextInputRef.focus();
            }
        }

        if (index === 3 && newPinDigits.every(digit => digit.length === 1)) {
            setLocked(true);
            const pin = newPinDigits.join('');
            let companyIdVerified = await verifyPin(pin);
            if (!companyIdVerified) {
                setErrorMessage('PIN is not correct');
                setPinDigits(['', '', '', '']);
                setLocked(false);
                setTimeout(() => {
                    inputRefs.current[0]?.focus();
                }, 0);
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
            } else {
                setLocked(false);
                setStep('tvNumber'); // Move to the TV number step
            }
        }
    };

    const handleTvNumberChange = (index: number, value: string) => {
        if (locked) return;

        const newTvNumber = [...tvNumber];
        newTvNumber[index] = value;
        setTvNumber(newTvNumber);

        if (value && index < 1) {
            const nextInputRef = inputRefs.current[index + 1];
            if (nextInputRef) {
                nextInputRef.focus();
            }
        }

        if (index === 1 && newTvNumber.every(digit => digit.length === 1)) {
            setStep('rememberPin'); // Move to remember PIN step
        }
    };

    // Function to request fullscreen mode
    const requestFullScreen = () => {
        const element = document.documentElement;
        if (element.requestFullscreen) {
            element.requestFullscreen().catch(err => {
                console.error(`Error attempting to enable full-screen mode: ${err.message}`);
            });
        }
    };

    // New handler for the remember PIN step
    const handleRememberChoice = (remember: boolean) => {
        if (remember) {
            localStorage.setItem('pin', pinDigits.join(''));
            const selectedTvId = tvIds[parseInt(tvNumber.join('')) - 1];
            localStorage.setItem('tvNumber', selectedTvId.toString());
            localStorage.setItem('companyId', companyId);
            localStorage.setItem('tvIds', JSON.stringify(tvIds));
        }

        // Request fullscreen before navigating
        requestFullScreen();

        // Navigate to play component
        navigate('/play', { state: { companyId: companyId, tvId: tvIds[parseInt(tvNumber.join('')) - 1] } });
        onClose();
    };

    return open ? (
        <div className="pin-dialog-backdrop">
            <div className="pin-dialog">
                {step === 'pin' ? (
                    <>
                        <h2>Enter PIN</h2>
                        <div className="pin-input-container">
                            {pinDigits.map((digit, index) => (
                                <input
                                    key={index}
                                    className="pin-input"
                                    type="tel"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleInputChange(index, e.target.value)}
                                    ref={el => inputRefs.current[index] = el}
                                    disabled={locked}
                                />
                            ))}
                        </div>
                    </>
                ) : step === 'tvNumber' ? (
                    <>
                        <h2>Enter TV Number</h2>
                        <div className="pin-input-container">
                            {tvNumber.map((digit, index) => (
                                <input
                                    key={index}
                                    className="pin-input"
                                    type="tel"
                                    maxLength={1}
                                    value={digit}
                                    onChange={(e) => handleTvNumberChange(index, e.target.value)}
                                    ref={el => inputRefs.current[index] = el}
                                    disabled={locked}
                                />
                            ))}
                        </div>
                    </>
                ) : (
                    <>
                        <h2>Do you want to remember the PIN?</h2>
                        <div className="button-container">
                            <button
                                autoFocus
                                className="no-button primary"
                                onClick={() => handleRememberChoice(false)}
                            >
                                No
                            </button>
                            <button
                                className="yes-button"
                                onClick={() => handleRememberChoice(true)}
                            >
                                Yes
                            </button>
                        </div>
                    </>
                )}
                {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>
        </div>
    ) : null;
};

export default PinDialog;
