import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export async function fetchAds(companyId: number, tvId: number) {
    try {
        const response = await axios.get(`${API_URL}/ad/getAllAdsForCompany`, {
            params: { companyId, tvId }
        });
        const { mergedAds, splitScreenOption } = response.data;
        return { ads: mergedAds, splitScreenOption };
    } catch (error) {
        console.error("Error fetching ads:", error);
        return { ads: [], splitScreenOption: 1 };
    }
}
